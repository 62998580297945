<template>
  <main v-if="packageObj">
    <PageTitleContainer><PageTitle>{{ packageObj.name }}</PageTitle></PageTitleContainer>

    <div class="container">
      <div>
        <p><strong>Name</strong>: {{ packageObj.name }}</p>
        <p><strong>Material</strong>: {{ packageObj.material }}</p>
        <p><strong>Volume</strong>: {{ packageObj.volume }}</p>
      </div>
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  $api.fetchPackage(to.params.id)
    .then(res => next(vm => {
      vm.packageObj = res?.data;
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      packageObj: null,
    };
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
